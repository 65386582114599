.spinner {
  border: 4px solid;
  border-radius: 50%;
  border-top: 4px solid transparent;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  color: var(--primary-color);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
